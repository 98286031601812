import EventBus from '../js/eventBus';

import { useAppStore } from '../store/app';

export const ipaFormFields = {
  hk: {
    appliedAmount: {
      prefix: { prefixFront: 'hkd' },
      type: 'number',
      onChange: ['monthlyRepaymentCalc']
    },
    disbursementAmount: {
      prefix: { prefixFront: 'hkd' },
      type: 'number'
    },
    interestRate: {
      prefix: { prefixBack: 'percentage' },
      type: 'number',
      onChange: ['monthlyRepaymentCalc']
    },
    annualRate: {
      prefix: { prefixBack: 'percentage' },
      type: 'number'
    },
    monthlyCost: {
      prefix: { prefixFront: 'hkd' },
      type: 'number'
    },
    loanPeriod: {
      prefix: { prefixBack: 'months' },
      type: 'number',
      onChange: ['monthlyRepaymentCalc']
    },
    processingFee: {
      prefix: { prefixBack: 'percentage' },
      type: 'number'
    },
    disbursementDate: {
      type: 'date'
    },
    functions: {
      monthlyRepaymentCalc (offerObj, appId) {
        const { loanPeriod, interestRate } = offerObj;
        let { appliedAmount } = offerObj;
        let monthlyRepayment = 0;

        appliedAmount = typeof appliedAmount === 'string' ? Number(appliedAmount.replace(/,/g, '')) : appliedAmount;

        if (appliedAmount !== undefined && (loanPeriod !== undefined && loanPeriod !== 0) && interestRate !== undefined) {
          monthlyRepayment = appliedAmount * (interestRate / 100) + appliedAmount / loanPeriod;
          monthlyRepayment = monthlyRepayment.toFixed(0);
          EventBus.$emit(`update-offer-var-${appId}`, { field: 'monthlyCost', val: monthlyRepayment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') });
        }
      }
    }
  },
  sg: {
    // offer amount given
    appliedAmount: {
      prefix: { prefixFront: 'sgd' },
      type: 'number',
      onChange: ['processingFeeCalc', 'nettLoanAmountCalc', 'monthlyCostCalc'] // NOTE: order matters!
    },
    // amount to be received
    nettLoanAmount: {
      prefix: { prefixFront: '$' },
      type: 'readOnly',
      defaultValue: 0
    },
    disbursementAmount: {
      prefix: { prefixFront: 'sgd' },
      type: 'number'
    },
    // per annum
    interestRate: {
      prefix: { prefixBack: 'percentage' },
      type: 'number',
      productDefault: {
        if: {
          field: 'currentProduct',
          values: ['accreditPersonalLoan', 'crawfortFinancePersonalLoan', 'singaCreditPersonalLoan', 'unitedCreditPersonalLoan', 'sandsCreditPersonalLoan'],
          defaultTo: 47
        },
      },
      onChange: ['monthlyCostCalc'],
    },
    monthlyCost: {
      prefix: { prefixFront: 'sgd' },
      type: 'number'
    },
    loanPeriod: {
      prefix: { prefixBack: 'months' },
      type: 'number',
      onChange: ['monthlyCostCalc'],
    },
    processingFee: {
      prefix: { prefixFront: 'sgd' },
      type: 'number',
      onChange: ['nettLoanAmountCalc']
    },
    disbursementDate: {
      type: 'date'
    },
    guarantorReq: {
      type: 'checkbox'
    },
    functions: {
      nettLoanAmountCalc (offerObj, appId) {
        let { appliedAmount = 0, processingFee = 0 } = offerObj;
        if (typeof appliedAmount === 'string') appliedAmount = appliedAmount ? Number(appliedAmount.replace(/,/g, '')) : 0;
        else appliedAmount = Number(appliedAmount);
        if (typeof processingFee === 'string') processingFee = processingFee ? Number(processingFee.replace(/,/g, '')) : 0;
        else processingFee = Number(processingFee);
        let nettLoanAmount = 0;

        if (processingFee > appliedAmount) EventBus.$emit(`update-offer-var-${appId}`, { field: 'nettLoanAmount', val: 0 });
        else if (appliedAmount !== undefined && processingFee !== undefined) {
          nettLoanAmount = appliedAmount - processingFee;
          EventBus.$emit(`update-offer-var-${appId}`, { field: 'nettLoanAmount', val: nettLoanAmount });
        }
      },
      processingFeeCalc (offerObj, appId) {
        const appStore = useAppStore();
        const { currentProduct } = offerObj;
        let { appliedAmount } = offerObj;
        const settings = appStore.currentProductSettings();
        appliedAmount = typeof appliedAmount === 'string' ? Number(appliedAmount.replace(/,/g, '')) : Number(appliedAmount);
        if (appliedAmount !== undefined && settings.defaultLoanFeeEnabled) {
          const processingFee = appliedAmount * settings.defaultLoanFeePercentage * 0.01;
          EventBus.$emit(`update-offer-var-${appId}`, { field: 'processingFee', val: (processingFee % 1 === 0) ? processingFee : processingFee.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') });
        }
      },
      monthlyCostCalc (offerObj, appId) {
        const { interestRate, loanPeriod } = offerObj;
        let { appliedAmount } = offerObj;

        appliedAmount = typeof appliedAmount === 'string' ? Number(appliedAmount.replace(/,/g, '')) : appliedAmount;

        if (appliedAmount !== undefined && interestRate !== undefined && loanPeriod !== undefined) {
          const P = Number(appliedAmount);
          const R = Number(interestRate) / 100 / 12;
          const N = Number(loanPeriod);
          const calcMonthlyCost = (P * R) / (1 - ((1 + R) ** (-1 * N)));
          EventBus.$emit(`update-offer-var-${appId}`, { field: 'monthlyCost', val: (calcMonthlyCost % 1 === 0) ? calcMonthlyCost : calcMonthlyCost.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') });
        }
      }
    }
  },
  au: {
    // offer amount given
    appliedAmount: {
      prefix: { prefixFront: 'sgd' },
      type: 'number',
      onChange: ['processingFeeCalc', 'nettLoanAmountCalc', 'monthlyCostCalc'] // NOTE: order matters!
    },
    // amount to be received
    nettLoanAmount: {
      prefix: { prefixFront: '$' },
      type: 'readOnly',
      defaultValue: 0
    },
    disbursementAmount: {
      prefix: { prefixFront: 'sgd' },
      type: 'number'
    },
    // per annum
    interestRate: {
      prefix: { prefixBack: 'percentage' },
      type: 'number',
      productDefault: {
        if: {
          field: 'currentProduct',
          values: ['accreditPersonalLoan', 'crawfortFinancePersonalLoan', 'singaCreditPersonalLoan', 'unitedCreditPersonalLoan', 'sandsCreditPersonalLoan'],
          defaultTo: 47
        },
      },
      onChange: ['monthlyCostCalc'],
    },
    monthlyCost: {
      prefix: { prefixFront: 'sgd' },
      type: 'number'
    },
    loanPeriod: {
      prefix: { prefixBack: 'months' },
      type: 'number',
      onChange: ['monthlyCostCalc'],
    },
    processingFee: {
      prefix: { prefixFront: 'sgd' },
      type: 'number',
      onChange: ['nettLoanAmountCalc']
    },
    disbursementDate: {
      type: 'date'
    },
    guarantorReq: {
      type: 'checkbox'
    },
    functions: {
      nettLoanAmountCalc (offerObj, appId) {
        let { appliedAmount = 0, processingFee = 0 } = offerObj;
        appliedAmount = Number(appliedAmount);
        processingFee = Number(processingFee);
        let nettLoanAmount = 0;

        if (processingFee > appliedAmount) EventBus.$emit(`update-offer-var-${appId}`, { field: 'nettLoanAmount', val: 0 });
        else if (appliedAmount !== undefined && processingFee !== undefined) {
          nettLoanAmount = appliedAmount - processingFee;
          EventBus.$emit(`update-offer-var-${appId}`, { field: 'nettLoanAmount', val: nettLoanAmount });
        }
      },
      processingFeeCalc (offerObj, appId) {
        const appStore = useAppStore();
        const { appliedAmount, currentProduct } = offerObj;
        const settings = appStore.currentProductSettings();
        if (appliedAmount !== undefined && settings.defaultLoanFeeEnabled) {
          const processingFee = appliedAmount * settings.defaultLoanFeePercentage * 0.01;
          EventBus.$emit(`update-offer-var-${appId}`, { field: 'processingFee', val: (processingFee % 1 === 0) ? processingFee : processingFee.toFixed(2) });
        }
      },
      monthlyCostCalc (offerObj, appId) {
        const { appliedAmount, interestRate, loanPeriod } = offerObj;

        if (appliedAmount !== undefined && interestRate !== undefined && loanPeriod !== undefined) {
          const P = Number(appliedAmount);
          const R = Number(interestRate) / 100 / 12;
          const N = Number(loanPeriod);
          const calcMonthlyCost = (P * R) / (1 - ((1 + R) ** (-1 * N)));
          EventBus.$emit(`update-offer-var-${appId}`, { field: 'monthlyCost', val: (calcMonthlyCost % 1 === 0) ? calcMonthlyCost : calcMonthlyCost.toFixed(2) });
        }
      }
    }
  }
};

export const giveIPAFieldList = {
  hk: ['appliedAmount', 'interestRate', 'loanPeriod', 'monthlyCost', 'processingFee', 'annualRate'],
  sg: ['appliedAmount', 'loanPeriod', 'interestRate', 'processingFee', 'monthlyCost', 'nettLoanAmount', 'guarantorReq'],
  au: ['appliedAmount', 'loanPeriod', 'interestRate', 'processingFee', 'monthlyCost', 'nettLoanAmount', 'guarantorReq'],
};

export const disburseLoanFieldList = {
  hk: ['appliedAmount', 'interestRate', 'loanPeriod', 'monthlyCost', 'processingFee', 'annualRate', 'disbursementDate'],
  sg: ['appliedAmount', 'loanPeriod', 'interestRate', 'processingFee', 'monthlyCost', 'nettLoanAmount', 'disbursementDate'],
  au: ['appliedAmount', 'loanPeriod', 'interestRate', 'processingFee', 'monthlyCost', 'nettLoanAmount', 'disbursementDate'],
};

export const declineCategory = {
  sg: [
    'bad_credit_history',
    'incorrect_info',
    'profile_issue',
    'customer_reject',
    'lender_policy'
  ]
};

export const declineSubCategory = {
  bad_credit_history: [
    'blacklisted',
    'poor_payment_record',
    'high_outstanding_loan',
    'bad_debt',
    'drs',
    'payment_jam',
    'multiple_credit_inquiries',
    'bankruptcy',
  ],
  incorrect_info: [
    'incomplete_no_proof_of_income',
    'no_proof_of_address',
    'no_identity_documents',
    'no_cpf',
    'missing_other_documents',
    'incorrect_income_declared',
    'incorrect_debt_declared',
    'cash_salary',
    'invalid_contact_info'
  ],
  profile_issue: [
    'low_income',
    'less_than_3m_employment',
    'invalid_employment',
    'lack_collateral'
  ],
  customer_reject: [
    'mia_no_show',
    'reject_offer',
    'customer_reconsider',
    'scam_customer',
    'customer_reluctant_for_appointment',
  ],
  lender_policy: [
    'existing_loan_lender',
    'family_banned',
    'self_exclusion',
    'others'
  ],
}

export const declineReasons = {
  hk: {
    lead: [
      'bad_TU_TE',
      'low_upload_rate_TE',
      'high_debt_related_TE',
      'write_off_related_TE',
      'repayment_related_TE',
      'repayment_related_TU',
      'high_debt_related_TU',
      'write_off_related_TU',
      'highDSR',
      'write_off_account',
      'poor_payment_record',
      'incomplete_income_proof',
      'missing_docs',
      'employment_nature',
      'low_income',
      'blacklisted',
      'other',
      'customer_non_pr',
      'customer_not_chinese_speaker',
      'OCA',
      'gambling_addiction',
      'subdivided_flats',
      'not_truthful'
    ],
    offerRevision: {
      offerRevisionGiven: [
        'bad_TU_TE',
        'low_upload_rate_TE',
        'high_debt_related_TE',
        'write_off_related_TE',
        'repayment_related_TE',
        'repayment_related_TU',
        'high_debt_related_TU',
        'write_off_related_TU',
        'highDSR',
        'write_off_account',
        'poor_payment_record',
        'incomplete_income_proof',
        'missing_docs',
        'employment_nature',
        'low_income',
        'blacklisted',
        'other',
        'customer_non_pr',
        'customer_not_chinese_speaker',
        'OCA',
        'gambling_addiction',
        'subdivided_flats',
        'not_truthful'
      ],
      offerRevisionChosen: [
        'bad_TU_TE',
        'low_upload_rate_TE',
        'high_debt_related_TE',
        'write_off_related_TE',
        'repayment_related_TE',
        'repayment_related_TU',
        'high_debt_related_TU',
        'write_off_related_TU',
        'highDSR',
        'write_off_account',
        'poor_payment_record',
        'incomplete_income_proof',
        'missing_docs',
        'employment_nature',
        'low_income',
        'blacklisted',
        'other',
        'customer_non_pr',
        'customer_not_chinese_speaker',
        'loan_amount_not_big_enough',
        'OCA',
        'customer_request_no_show',
        'customer_unwilling_to_fill_appform',
        'customer_borrowed_elsewhere',
        'MIA',
        'customerReject',
        'gambling_addiction',
        'subdivided_flats',
        'not_truthful'
      ],
      offerRevisionAcknowledged: [
        'bad_TU_TE',
        'low_upload_rate_TE',
        'high_debt_related_TE',
        'write_off_related_TE',
        'repayment_related_TE',
        'repayment_related_TU',
        'high_debt_related_TU',
        'write_off_related_TU',
        'highDSR',
        'write_off_account',
        'poor_payment_record',
        'incomplete_income_proof',
        'missing_docs',
        'employment_nature',
        'low_income',
        'blacklisted',
        'other',
        'customer_non_pr',
        'customer_not_chinese_speaker',
        'loan_amount_not_big_enough',
        'OCA',
        'customer_request_no_show',
        'customer_unwilling_to_fill_appform',
        'customer_borrowed_elsewhere',
        'MIA',
        'customerReject',
        'gambling_addiction',
        'subdivided_flats',
        'not_truthful'
      ],
    },
    chosen: [
      'bad_TU_TE',
      'low_upload_rate_TE',
      'high_debt_related_TE',
      'write_off_related_TE',
      'repayment_related_TE',
      'repayment_related_TU',
      'high_debt_related_TU',
      'write_off_related_TU',
      'highDSR',
      'write_off_account',
      'poor_payment_record',
      'incomplete_income_proof',
      'missing_docs',
      'employment_nature',
      'low_income',
      'blacklisted',
      'other',
      'customer_non_pr',
      'customer_not_chinese_speaker',
      'loan_amount_not_big_enough',
      'OCA',
      'customer_request_no_show',
      'customer_unwilling_to_fill_appform',
      'customer_borrowed_elsewhere',
      'MIA',
      'customerReject',
      'gambling_addiction',
      'subdivided_flats',
      'not_truthful'
    ],
    acknowledged: [
      'bad_TU_TE',
      'low_upload_rate_TE',
      'high_debt_related_TE',
      'write_off_related_TE',
      'repayment_related_TE',
      'repayment_related_TU',
      'high_debt_related_TU',
      'write_off_related_TU',
      'highDSR',
      'write_off_account',
      'poor_payment_record',
      'incomplete_income_proof',
      'missing_docs',
      'employment_nature',
      'low_income',
      'blacklisted',
      'other',
      'customer_non_pr',
      'customer_not_chinese_speaker',
      'loan_amount_not_big_enough',
      'OCA',
      'customer_request_no_show',
      'customer_unwilling_to_fill_appform',
      'customer_borrowed_elsewhere',
      'MIA',
      'customerReject',
      'gambling_addiction',
      'subdivided_flats',
      'not_truthful'
    ]
  },
  sg: [
    'low_income',
    'high_debt',
    'new_job',
    'poor_payment_record',
    'incomplete_income_proof',
    'missing_docs',
    'blacklisted',
    'MIA',
    'customerReject',
    'other'
  ],
  au: [
    'low_income',
    'high_debt',
    'new_job',
    'poor_payment_record',
    'incomplete_income_proof',
    'missing_docs',
    'blacklisted',
    'MIA',
    'customerReject',
    'other'
  ],
};

export const rejectReasons = {
  hk: [],
  sg: [],
  au: [],
};

export const productSettings = {
  hk: {
    defaultLoanFeePercentage: {
      handle (value) { return value; }
    }
  },
  sg: {
    defaultLoanFeePercentage: {
      handle (value) {
        return Math.min(Math.max(value, 0), 10.0)
      }
    }
  },
  au: {
    defaultLoanFeePercentage: {
      handle (value) {
        return Math.min(Math.max(value, 0), 10.0)
      }
    }
  }
}
